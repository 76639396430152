<template>
	<mw-dialog large v-model="dialog" cancel-btn :title="datetime">
		<template v-slot:button>
			<v-btn rounded class="mr-2 mb-2" x-small color="secondary" @click="dialog = true">
				{{date}}
			</v-btn>
		</template>
		<template v-slot:dialog-body>
			<div v-if="survey">
				<div v-for="question in questions" :key="question">
					<p
						class="subtitle-1 font-weight-bold"
						v-html="getTitle(question)"
					></p>
					<p v-if="!question.subquestions">
						<response-string
							:id="question"
							:value="response.data[question]"
						></response-string>
					</p>
					<p v-else>
						<span
							class="subtitle-2"
							v-for="subquestion in question.subquestions" :key="subquestion.value"
						>
							{{subquestion.text}}

						</span><br/>
				
						<response-string v-if="response.data[question]"
							:id="question"
							:value="response.data[question][subquestion.value]"
						></response-string>
					</p>
				</div>
			</div>
		</template>
	</mw-dialog>
</template>

<script>
import ResponseString from "@c/responses/ResponseString";
import MwDialog from "@c/ui/MwDialog";
export default {
	name: "Response",
	props: {
		id: { type: String },
		index: { type: [String, Number] },
	},
	data: () => {
		return {
			dialog: false,
		};
	},
	computed: {
		datetime(){
			return this.mwtimeutils.formatAsDateTimeString(this.response.created_at)
		},
		date(){
			return this.mwtimeutils.formatAsDateString(this.response.created_at)
		},
		response() {
			return this.$store.state.incentives.data[ this.id	];
		},
		survey() {
			return this.$store.getters["incentives/survey"];
		},
		questions() {
			return this.survey.questions
		},
	},
	components: {
		MwDialog,
		ResponseString,
	},
	methods: {
		getTitle(id){
			return this.$store.getters['questions/title'](id)
		}
	}
};
</script>
