<template>
    <div v-if="info">
        {{info.created_at ? info.created_at.toDate().toLocaleDateString() : ""}}: <br/>
        <span v-for="(question, id) in info.data" :key="id">
            <b>{{shortText(id)}}:</b> {{info[id]}} <br/>
        </span>
        <complete-response v-for="(r, i) in rewards" :id="r.id" :index="i" :key="i"> </complete-response>
    </div>
</template>

<script>
import CompleteResponse from "@c/incentives/CompleteResponse";
export default {
    props: {
        id: {type: String }
    }, 
	data: () => {
		return {
		};
	},
    components: {
        CompleteResponse
    },
    computed: {

        info(){
            return this.rewards[0]
        },
        rewards(){
            let rewards = this.$store.getters.getByKey("incentives", "user", this.id);
            rewards = this.mwutils.sortByDate(rewards, "created_at");
            return rewards;
        }
    }, 
	methods: {
		shortText(id){
			return this.$store.getters['questions/shortText'](id)
		}
	},
    created(){
        this.$store.dispatch("incentives/fetchUserRecords", this.id );
    }
   
}
</script>